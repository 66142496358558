<template>

  <div class="cta_main_view w-100">

    <div class="view_inner d-flex flex-column">

      <div class="view_center">
        <div class="steps_dot mb-3">

          <template v-if="getCampaignAdd.type === 'retargeting'">
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>

          </template>
          <template v-else-if="getCampaignAdd.type === 'integration'">
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle "></span>
          </template>
          <template v-else>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
          </template>

        </div>

        <retargeting :callType="'campaigns'" :addPixel="getCampaignAdd" :pixelListKey="'retargeting_ids'"></retargeting>


      </div>


      <div class="view_bottom">

        <div class="view_bottom_inner text-center" v-if="getCampaignAdd.type === 'integration'">
          <button @click="$router.push({ 'name': 'campaign_type' })" class="btn---cta  light-blue btn-round">
            <span>Go Back</span>
          </button>

          <button @click.prevent="handleThirdPartyRedirect()"
                  class="btn---cta btn-blue btn-round">
            <span>Next</span>
          </button>

        </div>

        <div class="view_bottom_inner text-center" v-else-if="getCampaignAdd.type === 'retargeting'">
          <button @click="$router.push({ 'name': 'campaign_type' })" class="btn---cta  light-blue btn-round">
            <span>Go Back</span>
          </button>

          <button :disabled="getCampaignLoaders.store" @click.prevent="handleSaveCampaign()"
                  class="btn---cta btn-blue d-inline-flex btn-round">
            <span>{{ getCampaignButtonText() }}</span>
            <clip-loader v-if="getCampaignLoaders.store" class="ml-1" :size="size" :color="color"></clip-loader>
          </button>

        </div>
        <!-- call to action -->
        <div class="view_bottom_inner text-center" v-else>
          <button @click="$router.push({ 'name': 'triggers' })" class="btn---cta  light-blue btn-round">
            <span>Go Back</span>
          </button>


          <button @click.prevent="handleSaveCampaign()"
                  class="btn---cta btn-blue btn-round ">
            <span>{{ getCampaignButtonText() }}</span>
          </button>
          <!-- commented because we need add save campaign button  case (retarget + cta) -->
          <!--                    <button   @click.prevent="handleCTATriggersRedirect()"-->
          <!--                       class="btn-&#45;&#45;cta btn-blue btn-round">-->
          <!--                        <span>Next</span>-->
          <!--                    </button>-->

        </div>


      </div>
    </div>
  </div>


</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Retargeting from '@/views/pages/campaigns/call-to-actions/steps/retargeting/Retargeting'

export default ({

  data () {
    return {
      size: '14px',
      color: '#fff'
    }
  },
  components: {
    Retargeting
  },
  created () {
    if (!this.getCampaignAdd.brand_id) {
      this.$router.push({ name: 'create' })
    }
  },

  computed: {

    ...mapGetters([
      'getCampaignAdd',
      'getCampaignLoaders'
    ])

  },

  methods: {
    ...mapActions([]),
    validateScriptsAdded () {
      if (this.getCampaignAdd.retargeting_ids.length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add retargeting pixels to create a campaign.',
          type: 'error'
        })
        return false
      }
      return true
    },
    async handleSaveCampaign () {
      this.checkCampaignValidation()
      let res = await this.validateScriptsAdded()
      if (res) {
        // mixin method
        this.storeCampaign()
      }
    },
    handleThirdPartyRedirect () {
      this.checkCampaignValidation()
      let res = this.validateScriptsAdded()
      if (res) {
        this.$router.push({ name: 'third_party_integration' })
      }
    },
    handleCTATriggersRedirect () {
      this.checkCampaignValidation()
      if (this.validateScriptsAdded()) this.$router.push({ name: 'triggers' })
    },
    handleTriggersCampaign () {
      // mixin method
      this.storeCampaign()
    }
  },
  watch: {}

})
</script>
