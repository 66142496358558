<template>

  <div class="retargeting-block">
    <add-pixel-modal :addPixel="addPixel" :pixelListKey="pixelListKey" :callType="callType" ref="add_pixel_modal"></add-pixel-modal>
    <template v-if="getPixelList.length > 0">

      <b-dropdown right class=" dropdown-menu-right default_style_dropdown checkbox_dropdown " ref="pixel_dropdown"
                  :no-caret="true">
        <div class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
             slot="button-content">
          <span class="text" data-cy="select-retargeting">Select Your Retargeting Script</span>
          <span class="arrow_icon ml-auto">
                                <i class=" fal fa-angle-down ml-3"></i>
                             </span>

        </div>
        <ul class="inner ">

          <template v-if="getPixelList.length > 0">
              <li class="list_item_li" v-for="pixel in getPixelList" data-cy="retargeting-pixel">
                <div class="checkbox_input_image" @click="$refs.pixel_dropdown.hide()">
                  <input v-model="addPixel[pixelListKey]" type="checkbox" :id="pixel._id" :value="pixel._id">
                  <label class="checkbox_right with_checked_icon" :for="pixel._id" >
                    <span class="icon mr-2" :class="getPixelSocialClass(pixel.type ,1)"><i :class="getPixelSocialClass(pixel.type,2)"></i></span>
                    {{ limitTextLength(pixel.name, 25) }}
                    {{ getGooglePixelOnKey(pixel.type, pixel.key, googlePixelsTypeKey) }}
                  </label>
                </div>
              </li>
          </template>
          <li v-else class="no_text_li">
            You do not have any Retargeting Pixel.
          </li>

        </ul>
      </b-dropdown>

      <div class="or_separator">
        <span class="sep_text">OR</span>
      </div>
    </template>

    <div class="text-center">
      <button class="w-100 d-block btn---cta btn-border btn-round  btn-bold"
              @click.prevent="showAddPixelModal($refs.add_pixel_modal, 'campaign')">
        <span>Add New Retargeting Script</span>
      </button>
    </div>

    <div v-if="addPixel[pixelListKey] && addPixel[pixelListKey].length > 0"
         class="retargeting-list social_icons_square   checkbox-list  ">
      <p class="list-title">Selected Retargeting Scripts</p>
      <div v-for="pixel in addPixel[pixelListKey]" class="list-item d-flex align-items-center">
        <p class="text d-flex align-items-center">
                    <span class="icon" :class="getPixelSocialClass(getPixelData(pixel).type ,1)">
                        <i :class="getPixelSocialClass(getPixelData(pixel).type,2)"></i>
                    </span>
          <span v-tooltip="limitTextLength(getPixelData(pixel).name, 25)">{{
              limitTextLength(getPixelData(pixel).name, 25)
            }}</span>
          <span class="ml-2">{{
              getGooglePixelOnKey(getPixelData(pixel).type, getPixelData(pixel).key, googlePixelsTypeKey)
            }}</span>

        </p>
        <i class="fal fa-times ml-auto remove-icon" v-tooltip.top-center="'Remove'"
           @click.prevent="removePixelFromCampaign(pixel)"></i>
      </div>

    </div>

  </div>

</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import {pixelTypes} from '@/state/modules/mutation-types'
import {
  pixelMessages,
  pixelValidations
} from '@/common/attributes'
import AddPixelModal from "@/views/pages/setting/pixels/dialogs/AddPixelModal";
import {googlePixelsTypeKey} from '@/common/attributes'

export default ({
  components: {
    Dropdown,
    AddPixelModal
  },
  data () {
    return {
      size: '14px',
      color: '#fff',
      validations: pixelValidations,
      messages: pixelMessages,
      googlePixelsTypeKey: googlePixelsTypeKey
    }
  },

  props: [
    'addPixel',
    'pixelListKey',
    'callType'
  ],

  computed: {
    ...mapGetters([
      'getPixelList',
      'getAddPixel',
    ])
  },

  methods: {
    ...mapActions([
      'storePixel'
    ]),
    ...mapMutations([
      pixelTypes.SET_PIXEL
    ]),
    selectPixel (pixel) {
      this.getAddPixel.type = pixel
    },
    removePixelFromCampaign (pixelId) {
      this.addPixel[this.pixelListKey].splice(this.addPixel[this.pixelListKey].indexOf(pixelId), 1)
    },
    getPixelData (pixelId) {
      let item = this.getPixelList.find((item, index) => {
        return item._id === pixelId
      })
      return item ? item : {}
    },

    checkCTAPixel (pixelId) {
      let retargetingIds = this.addPixel[this.pixelListKey]
      if (retargetingIds) {
        if (retargetingIds.includes(pixelId)) {
          return 'checked'
        }
      }
      return false
    }
  },

  watch: {
    'getAddPixel.pixel' (value) {
      if (value && value.trim().length > 0) this.validations.pixel = false
    },
    'getAddPixel.name' (value) {
      if (value && value.trim().length > 0) this.validations.name = false
    }
  }

})
</script>
